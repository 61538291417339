.App {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    & * {
        font-family: 'Roboto', sans-serif;
        font-size: calc(0.7rem + 0.3vw);
    }

    &__content {
        padding: 0 20px;
        //max-width: 1000px;
        max-width: 80%;
        display: flex;
        flex-direction: column;
        flex: 1;


    }
    &__typesList {
        border-bottom: 2px solid #3B43D4;
        padding-left: 20px;
    }
    &__constructor {
        display: grid;
        //grid-template-columns: minmax(min-content, 1fr) 430px;
        grid-template-columns: minmax(min-content, 60%) minmax(250px, 1fr);
        margin-bottom: 10px;
    }
    &__optionsSection {
        box-sizing: border-box;
        padding-left: 20px;
        border-right: 2px solid #3B43D4;
    }
    &__imageSection {
        position: relative;
        border-bottom: 2px solid #3B43D4;
    }
    &__tableImage {
        aspect-ratio: 1 / 1;
        position: sticky;
        top: 10px;
        margin-top: 5px;
        margin-left: 5px;
    }
    &__formActionContainer {
        width: 300px;
        margin: 0 auto;
    }



    &__con-body {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(100px, 550px) min-content;
    }
    &__con-form {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 855px) {
    .App__optionsSection {
        border-right: none;
    }
    .App__constructor {
        display: flex;
        flex-direction: column;
    }
    .App__imageSection {
        height: calc(13rem + 11vw);
        margin: 10px 6px;
        border-radius: 5px;
        border: 1px solid #3B43D4;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 1100px) {
    .App__content {
        max-width: 100%;
        padding: 0;
    }
    .App__optionsSection {
        padding-left: 0;
    }
}
