.FsShelf {

  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    & > div {
      gap: 0;
    }
  }
}