.ProductTypesSection {
  display: flex;
  justify-content: space-between;

  &__full > *:not(:last-child) {
    margin-right: 5px;
  }

  &__full {
    display: block;
  }
  &__dropdown {
    display: none;
  }
  &__backlink {
    display: flex;
    align-items: center;
    padding: 0 4px;
    margin-right: 13px;
    font-size: calc(0.9rem + 0.2vw);
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    line-height: 36px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: none;
    font-family: 'Roboto', sans-serif;

    transition: color .3s;
    color: #1b1b1d;

    &:hover {
      color: #3B43D4;
    }
  }
}
@media screen and (max-width: 800px) {
  .ProductTypesSection__full {
    display: none;
  }
  .ProductTypesSection__dropdown {
    display: block;
  }
}

@media screen and (max-width: 1300px) {
  .TableTypeSingleButton, .ProductTypesSection__backlink {
    font-size: 14px !important;
  }
}