.FormItemContainer {

  &__title {
    margin: 0;
    padding: 10px 0;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    color: #000;
    font-weight: 300;
    text-align: center;
  }
  &__body {
    display: flex;
    flex-direction: column;

  }
}