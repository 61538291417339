.TableTypeSingleButton {
  position: relative;
  display: inline-block;
  padding: 2px 12px 2px 12px;
  border: 2px solid transparent;
  border-bottom-width: 0;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  font-size: 21px;
  line-height: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-family: 'Roboto', sans-serif;

  transition: color .3s, background-color .3s;
  color: #1b1b1d;
  background-color: transparent;
  border-radius: 13px 13px 0 0;

  &--active {
    border-color: #3B43D4;
    border-bottom-color: transparent;
    background-color: white;
    color: #444444;
    padding: 0px 12px 2px 12px;
    bottom: -2px;
  }
  &:hover {
    background-color: #3B43D4;
    color: white;
    padding: 0px 12px 2px 12px;
    margin-bottom: -2px;
  }
}
